<template>
    <div
        v-if="mode === 'edit'"
        class="editor-buttons"
    >
        <div
            v-if="editing"
            class="editor-form__buttons"
        >
            <custom-button
                default
                class="blue-button"
                type="submit"
            >
                UPDATE {{ type }} PAGE
            </custom-button>
            <custom-button
                default
                type="button"
                class="grey-button"
                @on-btn-click="openResetModal"
            >
                RESET
            </custom-button>
        </div>
        <div v-else>
            <custom-button
                default
                class="grey-button"
                type="button"
                @on-btn-click="back"
            >
                GO BACK
            </custom-button>
        </div>
    </div>
    <div
        v-else-if="mode === 'create'"
        class="editor-buttons editor-form__buttons"
    >
        <custom-button
            default
            class="blue-button"
            type="submit"
        >
            CREATE {{ type }} PAGE
        </custom-button>
        <custom-button
            default
            class="grey-button"
            type="button"
            @on-btn-click="openResetModal"
        >
            RESET
        </custom-button>
    </div>
</template>

<script>
export default {
    name: 'EditButtons',
    props: {
        editing: Boolean,
        mode: String,
        type: String
    },
    methods: {
        openResetModal() {
            this.$emit('openResetModal')
        },
        back() {
            this.$emit('back')
        }
    }
}
</script>

<style scoped>

</style>
