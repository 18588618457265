<template>
    <div class="editor-layout__header">
        <div class="editor-layout__header-info__block">
            <button
                class="editor-layout__header-info__block-back"
                @click="$router.back()"
            >
                <arrow />
                BACK
            </button>
            <div class="editor-layout__header-info__block-icons">
                <div class="editor-layout__header-info__block-icons_status">
                    <template v-if="published === 1">
                        <status-ok />
                        Published
                    </template>
                    <template v-else-if="published === 0">
                        <status-error />
                        Unpublished
                    </template>
                </div>
                <div
                    v-if="(typeof locked !== 'undefined')"
                    class="editor-layout__header-info__block-icons_status"
                >
                    locked {{ locked }}
                </div>
                <div
                    v-if="revisions"
                    class="editor-layout__header-info__block-icons_revisions"
                >
                    <revisions
                        :id="id"
                        :revisions="revisions"
                        text
                    />
                </div>
            </div>
        </div>
        <h1 class="editor-layout__header-title">
            {{ title }}
        </h1>
        <h2 class="editor-layout__header-subtitle">
            {{ url }}
        </h2>
    </div>
</template>

<script>
import Arrow from '@/icons/Arrow';
import Revisions from '../Revisions';

export default {
    name: 'EditorHeader',
    components: {
        Arrow,
        Revisions
    },
    props: {
        title: String,
        url: String,
        published: {
            type: Number
        },
        revisions: {
            type: Number
        },
        locked: Number,
        id: Number
    }
}
</script>

<style lang="scss" scoped>
    .editor-layout__header {
        margin-bottom: 30px;
        &-info__block {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            &-back {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                height: 40px;
                width: 80px;
                font-size: 16px;
                font-weight: bold;
                outline: none;
                cursor: pointer;
                transition: .1s;
                svg {
                    margin-right: 10px;
                    height: 14px;
                }
                &:hover {
                    font-size: 18px;
                    svg {
                        height: 16px;
                    }
                }

            }
            &-icons {
                display: flex;
                align-items: center;
                &_status {
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                    svg {
                        height: 28px;
                        width: 28px;
                        margin-right: 10px;
                    }
                    color: rgba(0,0,0,.87);
                }
                &_revisions {
                    margin-left: 20px;
                }
            }
        }
        &-title {
            font-size: 26px;
            margin: 0 0 10px;
            text-transform: capitalize;
        }
        &-subtitle {
            font-weight: 400;
            font-size: 16px;
            font-style: italic;
            margin: 0;
        }
    }
</style>
