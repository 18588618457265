<template>
    <div class="editor-form__description editor-layout__content-form__field">
        <form-textarea
            v-if="!disabled"
            v-model="description"
            :field.sync="description"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            legend="Description"
            placeholder="Description"
            @onChange="onChange($event)"
        />
        <div
            v-else
            class="content-form__description-block"
        >
            <span class="content-form__description--legend">
                Description
            </span>
            <div
                class="content-form__description--content"
                v-html="description"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditorDescription',

    props: ['value', 'errors', 'isValid', 'config', 'disabled'],
    data() {
        return {
            description: this.value
        }
    },
    watch: {
        value(val) {
            this.description = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style lang="scss">
</style>
