// Add dropdown with banners

import FroalaEditor from 'froala-editor';

FroalaEditor.DefineIcon('banners', { NAME: 'cog', SVG_KEY: 'cogs' });
FroalaEditor.RegisterCommand('banners', {
    title: 'Add banner',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: {
        banner1: 'Banner with book',
        banner2: 'Banner with pencil',
        banner3: 'Deadline Approaching'
    },
    callback(cmd, val) {
        if (val === 'banner1') {
            this.html.insert(`
                    <section class="banner fr-deletable" id="banner--help">
                        <div class="guide-banner-img">
                            <img data-src="/img/guide.jpg" src="/img/guide.jpg" alt="Wrining guide" class="guide-book">
                        </div>
                        <div class="banner__descr">
                            <p class="banner__text banner__text_guide"><span>Get free</span><br/>Wrining guide</p>
                            <a href="${process.env.VUE_APP_BANERS_URL || 'https://shop.customessaymeister.com/order/new'}" class="btn-base btn-base_large banner__btn banner__btn_guide">Download</a>
                        </div>
                    </section><p></p>`);
        } else if (val === 'banner2') {
            this.html.insert(`<section class="banner banner-pencil fr-deletable" id="banner--pencil">
                    <div class="help-banner-img">
                        <img data-src="/img/help-writing/Body.svg" src="/img/help-writing/Body.svg" alt="body" class="help-banner-img__body">
                        <img data-src="/img/help-writing/Left-hand.svg" src="/img/help-writing/Left-hand.svg" alt="left-hand" class="help-banner-img__left-hand">
                        <img data-src="/img/help-writing/Right-hand.svg" src="/img/help-writing/Right-hand.svg" alt="right-hand" class="help-banner-img__right-hand">
                    </div>
                    <div class="banner__descr banner__descr--nopadl">
                        <p class="banner__text">Need an essay? I can help!</p>
                        <a href="${process.env.VUE_APP_BANERS_URL || 'https://shop.customessaymeister.com/order/new'}" class="btn-base btn-base_large btn-base_orange banner__btn">Learn more</a>
                    </div>
                </section><p></p>`);
        } else if (val === 'banner3') {
            this.html.insert(`
            <div class="deadline-popup" id="deadline-popup">
                <span class="close-popup"></span> 
                <p class="deadline-popup__title">
                    Deadline Approaching?
                </p>
                <p class="deadline-popup__text">
                    We work 24/7 and we are affordable (from $13.95/page).
                    Our writers, managers and support agents all have been involved in academic ghostwriting for years.
                    We can assist even with the most difficult writing assignment under time constraints.
                </p>
                <div class="deadline-popup__icons">
                    <div class="deadline-popup__icons-container"><img src="/img/dedline-banner/popup-candle.svg" alt="candle" class="deadline-popup__icon"> <span class="deadline-popup__icon-desc">Our writers will hit the bull's eye on your project!</span></div>
                    <div class="deadline-popup__icons-container"><img src="/img/dedline-banner/popup-clock.svg" alt="clock" class="deadline-popup__icon"> <span class="deadline-popup__icon-desc">The paper will be delivered on time!</span></div>
                    <div class="deadline-popup__icons-container"><img src="/img/dedline-banner/popup-finger.svg" alt="finger" class="deadline-popup__icon"> <span class="deadline-popup__icon-desc">100% authentic writing! No plagiarism!</span></div>
                    <div class="deadline-popup__icons-container"><img src="/img/dedline-banner/popup-lock.svg" alt="lock" class="deadline-popup__icon"> <span class="deadline-popup__icon-desc">Fast &amp; secure ordering!</span></div>
                </div>
                <a href="${process.env.VUE_APP_BANERS_URL || 'https://shop.customessaymeister.com/order/new'}" class="btn-deadline btn-base_orange btn-base_normal btn-base">place an order</a>
            </div>
            `)
        }
    }
});

// End Add dropdown with banners
