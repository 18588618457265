import './FroalaBanners.js'
import { eventBus } from '@/helpers/event-bus'
import Auth from '../auth/index.js'

export const config = {
    key: 'lSA3D-17D1E1G3A1A1H1rXYb1VPUGRHYZNRJd1JVOOb1HAc1zG2B1A2C2D6B1F2C4F1A3==',
    placeholderText: 'Edit Your Content Here!',
    charCounterCount: true,
    listAdvancedTypes: false,
    spellcheck: false,
    attribution: false,
    pluginsEnabled: [
        'align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'file', 'fontAwesome', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'inlineClass', 'lineBreaker', 'lineHeight', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'entities', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],

    toolbarButtons: [
        [
            'clearFormatting',
            'selectAll',

            '|',

            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'subscript',
            'superscript',

            'fontFamily',
            'fontSize',
            'textColor',
            'backgroundColor',

            '|',
            'inlineStyle',
            'paragraphStyle',

            '|',

            'paragraphFormat',
            'align',
            'formatOL',
            'formatUL',
            'outdent',
            'indent',
            'quote',

            '-',
            'fullscreen',
            'html',
            'print',
            // does not work
            // 'getPDF',

            '|',

            'insertImage',
            'insertLink',
            'insertVideo',
            'insertFile',
            'insertTable',
            '|',

            'specialCharacters',
            'insertHR',

            '|',

            'undo',
            'redo',

            '|',

            'inlineClass',
            'banners'
        ]
    ],
    quickInsertButtons: [
        'image',
        'video',
        'table',
        'ol',
        'ul',
        'myButton'
    ],
    paragraphFormat: {
        N: 'Normal',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
        PRE: 'Code'
    },
    codeMirror: window.CodeMirror,
    codeMirrorOptions: {
        indentWithTabs: true,
        lineNumbers: true,
        lineWrapping: true,
        mode: 'text/html',
        tabMode: 'indent',
        tabSize: 2
    },

    tableStyles: {
        'table--header-border': 'header-border',
        'pluses-minuses': 'pluses-minuses',
        'table--no-border': 'Two column'
    },

    tableCellStyles: {
        'background-good': 'background-good',
        'background-bad': 'background-bad'
    },

    inlineClasses: {
        'prepend-icon-plus': 'icon plus',
        'prepend-icon-minus': 'icon minus',
        'prepend-icon-unchecked': 'icon uncheck',
        'prepend-icon-checked': 'icon check',
        'prepend-icon-arrowtoleft': 'icon arrow to left',
        'prepend-icon-toright': 'icon arrow to right',
        'text-bg': 'backgound text'
    },
    events: {
        'froalaEditor.initialized': function () {
        },
        // eslint-disable-next-line consistent-return
        'image.beforeUpload': function () {
            this.opts.requestHeaders.authorization = `Bearer ${Auth.getToken()}`
        },
        'image.uploaded': function (response) {
            console.log('uploaded');
            const img_url = JSON.parse(response).url;
            this.image.insert(img_url, false, null, this.image.get(), response);
            return false;
        },
        'image.inserted': function ($img, response) {
            // Do something here.
            // this is the editor instance.
            if (!response) {
                this.image.remove($img)
                eventBus.$emit('showSnackBar', 'Image type not allowed', 'error');
            }
        },
        'link.beforeInsert': function (e) {
            const dotsArray = e.match(/\./g)
            if (!dotsArray || dotsArray.length > 1 || e.includes('.html')) {
                const view = this.$el[0]
                setTimeout(() => {
                    this.selection.save();
                    view.innerHTML = view.innerHTML.replace(`<a href="http://${e}`, `<a href="${process.env.VUE_APP_OUTREACH_URL}/${e}`)
                    this.selection.restore();
                }, 100)
                return `${process.env.VUE_APP_DOMAIN}/${e}`
            }
            return e
        }
    },
    linkAlwaysBlank: true,
    imagePaste: false,
    inlineMode: false,
    plainPaste: true,
    pasteAllowedStyleProps: [],
    charCounterMax: 30000,
    // Set the image upload parameter.
    imageUploadParam: 'file',

    // Set the image upload URL.
    imageUploadURL: `${process.env.VUE_APP_API_URL}/api/file-storage/content/upload-image`,

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 5 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: [
        'jpeg',
        'jpg',
        'png'
    ]
}
