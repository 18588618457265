<template>
    <div class="editor-form__meta-title editor-layout__content-form__field">
        <form-input
            v-model="title_secondary"
            :field.sync="title_secondary"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            legend="Title Secondary"
            placeholder="Title Secondary"
            name="Title Secondary"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorTitleSecondary',

    props: ['value', 'errors', 'isValid', 'config', 'disabled'],
    data() {
        return {
            title_secondary: this.value
        }
    },
    watch: {
        value(val) {
            this.title_secondary = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>
