<template>
    <div class="editor-form__link editor-layout__content-form__field">
        <form-input
            v-model="link"
            :field.sync="link"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            :legend="`https://www.customessaymeister.com/${type}/${value}`"
            :placeholder="`https://www.customessaymeister.com/${type}/your-link`"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorLink',

    props: ['value', 'errors', 'isValid', 'config', 'disabled', 'type'],
    data() {
        return {
            link: this.value
        }
    },
    watch: {
        value(val) {
            this.link = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>
