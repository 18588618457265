<template>
    <div class="editor-form__meta-title editor-layout__content-form__field">
        <form-input
            v-model="meta_title"
            :field.sync="meta_title"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            legend="<Meta Title> tag"
            placeholder="Meta Title tag"
            name="Meta Title"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorMetaTitle',

    props: ['value', 'errors', 'isValid', 'config', 'disabled'],
    data() {
        return {
            meta_title: this.value
        }
    },
    watch: {
        value(val) {
            this.meta_title = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>
