<template>
    <div class="editor-form__content editor-layout__content-form__field">
        <div v-show="!disabled">
            <froala
                id="froala-editor"
                ref="froala"
                v-model="content"
                :tag="'textarea'"
                :config="froalaConfig"
                :field.sync="content"
                @input="onChange($event)"
            />
        </div>
        <div
            v-show="disabled"
            class="editor-form__content-block"
        >
            <div class="editor-form__content--legend">
                Content
            </div>
            <div
                class="editor-form__content--content"
                v-html="content"
            />
        </div>
    </div>
</template>

<script>
import { config } from '@/helpers/froala';

export default {
    name: 'EditorContent',

    props: ['value', 'config', 'disabled'],
    data() {
        return {
            content: this.value ? this.value : '',
            froalaConfig: config
        }
    },
    watch: {
        value(val) {
            this.content = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style lang="scss">
    .editor-form__content {
        &-block {
            position: relative;

        }

        &--legend {
            font-family: 'Mark Pro', sans-serif;
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -8px;
            border: none;
            z-index: 1;
            background: #fff;
            line-height: 16px;
        }

        &--content {
            border-radius: 4px;
            background: rgb(248, 248, 248);
            padding: 15px 10px;
        }
    }
.fr-view {
    max-width: 890px;
    font-size: 16px!important;
    line-height: 24px!important;
    font-family: Mark Pro,sans-serif!important;
}
.fr-view p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: left;
    color: #1f2939;
    margin-bottom: 16px;
}
.fr-view h2, .fr-view h3 {
    font-family: Mark Pro,sans-serif;
    line-height: 1.36;
    font-weight: 700;
    color: #1e2838;
    padding: 0;
    margin: 60px 0 40px;
}
.fr-view h2 {
    font-size: 26px;
}
.fr-view a {
    color: #ff8a1a!important;
    cursor: pointer;
}
</style>
