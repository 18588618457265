<template>
    <div class="thumbnail editor-form__img editor-layout__content-form__field">
        <div
            v-if="!cropedImage"
            class="thumbnail__dropbox"
            :class="{ 'error-field': errors, 'success-field': isValid || image, 'disabled-field': disabled }"
            :style="`background-image: url(/img/thumbnail.svg)`"
        >
            <CircleProgress
                v-if="uploadPercentage > 0 && !cropedImage"
                :progress="uploadPercentage"
            />
            <div class="thumbnail__dropbox-wrapper">
                <label for="files_upload">
                    <input
                        id="files_upload"
                        ref="input"
                        type="file"
                        class="thumbnail__input-file"
                        :disabled="disabled"
                        @input="addFile($event)"
                    >
                    Upload image here
                </label>
            </div>
        </div>
        <template
            v-else
        >
            <div
                class="thumbnail__image-block"
            >
                <img
                    class="thumbnail__image-block--img"
                    :src="cropedImage"
                    alt=""
                >
                <custom-button
                    class="thumbnail__image-block--button"
                    default
                    type="button"
                    :disabled="disabled"
                    @on-btn-click="removeImage"
                >
                    Remove Image
                </custom-button>
            </div>
        </template>
        <croper-modal
            title="Crop your photo"
            name="content-img"
            @rotate="rotate"
        >
            <template #content>
                <vue-cropper
                    v-if="image"
                    ref="cropper"
                    :src="image"
                    alt="Source Image"
                    :aspect-ratio="11 / 8"
                    preview=".preview"
                    class="avatar-cropper"
                />
            </template>
        </croper-modal>
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus';
import CroperModal from '@/components/modals/CroperModal'
import CircleProgress from '@/components/common/CircleProgress'
import modalsMixins from '@/mixins/modals/modalsMixins'
import VueCropper from 'vue-cropperjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import Compressor from 'compressorjs';
import 'cropperjs/dist/cropper.css'

export default {
    name: 'EditorImg',
    components: { CroperModal, VueCropper, CircleProgress },
    mixins: [modalsMixins],

    props: ['value', 'config', 'errors', 'isValid', 'disabled'],
    data() {
        return {
            image: '',
            cropedImage: '',
            fileName: '',
            uploadPercentage: 0
        }
    },
    watch: {
        value(newVal) {
            this.cropedImage = newVal
        }
    },
    created() {
        this.cropedImage = this.value
        eventBus.$on('hideModal', this.hideModal)
        eventBus.$on('confirm', this.sendImage)
    },
    methods: {
        hideModal(name) {
            if (name !== 'content-img') { return }
            this.image = ''
            this.$refs.input.value = ''
        },
        loadImage(formData) {
            return Api.post('/api/file-storage/content/upload-cover-image',
                formData,
                {
                    'Content-Type': 'multipart/form-data',
                    onUploadProgress: (progressEvent) => {
                        this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                })
                .then((response) => {
                    this.cropedImage = response.data.url
                })
                .catch((error) => {
                    eventBus.$emit('showSnackBar', error.message, 'error');
                })
        },
        sendImage(name) {
            if (name !== 'content-img') { return }
            const $vm = this
            this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
                new Compressor(blob, {
                    quality: 0.6,
                    async success(result) {
                        if (result.size / 1024 / 1024 > 8) {
                            eventBus.$emit('showSnackBar', 'File size exceeds 8MiB', 'error');
                            throw new Error('Error')
                        }
                        const formData = new FormData();
                        formData.append('file', result, $vm.fileName);
                        await $vm.loadImage(formData)
                        $vm.$emit('imageInput', $vm.cropedImage)
                        $vm.$emit('update:field', $vm.cropedImage)
                        $vm.$emit('input', $vm.cropedImage)
                        $vm.hideModal(name)
                    },
                    error(err) {
                        console.log(err.message);
                    }
                });
            });
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        addFile(e) {
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            this.fileName = file.name
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
                this.openModal('content-img')
            };
            reader.readAsDataURL(file);
        },
        removeImage() {
            this.cropedImage = ''
            this.uploadPercentage = 0
            this.$emit('imageInput', this.cropedImage)
            this.$emit('update:field', this.cropedImage)
            this.$emit('input', this.cropedImage)
        }
    }
}
</script>

<style lang="scss" scoped>
.thumbnail {
    display: flex;
    &__dropbox {
        &.error-field {
            outline: 2px dashed red;
        }
        &.success-field {
            outline: 2px dashed green;
        }
        &.disabled-field {
            background-color: #f8f8f8;
        }
        outline: 2px dashed #d3d9e2;
        background-position: 50%;
        background-repeat: no-repeat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #1f2939;
        padding: 150px 10px 15px;
        position: relative;
        cursor: pointer;
        text-align: center;
        width: 200px;
        height: 200px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 4px;
        z-index: 2;
        &-wrapper {
            margin: 10px 0 20px;
        }
        label {
        }
    }
    &__input-file {
        opacity: 0;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        position: absolute;
        cursor: pointer;
    }
    &__image-block {
        padding: 4px;
        &--img {
            outline: 2px dashed green;
            padding: 4px;
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-height: 130px;
            min-height: 130px;
            //height: 100%;
            margin-bottom: 20px;
        }
    }
    .image_progress{
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
