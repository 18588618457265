<template>
    <div class="editor-form__button-title editor-layout__content-form__field">
        <form-input
            v-model="button_keywords"
            :field.sync="button_keywords"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            legend="Button Keywords"
            placeholder="Button Keywords"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorButtonTitle',

    props: ['value', 'errors', 'isValid', 'config', 'disabled'],
    data() {
        return {
            button_keywords: this.value
        }
    },
    watch: {
        value(val) {
            this.button_keywords = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>
