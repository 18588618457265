const formatingBulkError = (text) => text
    .replace('url_in_bulk.', 'Link ')
    .replace(/\d/, (match) => +match + 1)

class ErrorHandler {
    singleServerError({ errors = null, message = '' }) {
        const errorSnackBarText = (errors && errors[Object.keys(errors)[0]][0]) || message || 'Server error'
        return {
            errorSnackBarText
        }
    }

    getBulkError({ errors = null, message = '' }) {
        let errorSnackBarText
        let inputError = ''
        console.log(errors)
        if (errors) {
            const error = Object.entries(errors)[0]
            inputError = `${formatingBulkError(error[0])}: ${formatingBulkError(error[1][0])}`
            if (Array.isArray(errors)) {
                errorSnackBarText = errors.reduce((acc, error, index) => `${acc}Link ${index + 1}: ${error[0]}.  `, '')
            } else {
                errorSnackBarText = Object.values(errors).reduce((acc, error, index) => `${acc}Link ${index + 1}: ${error[0]}.  `, '')
            }
        } else {
            errorSnackBarText = message || 'Server error'
            inputError = errorSnackBarText
        }
        return {
            inputError, errorSnackBarText
        }
    }
}

export const errorHandler = new ErrorHandler()
