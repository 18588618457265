<template>
    <phone-number />
</template>

<script>
export default {
    name: 'EditorPhone'
}
</script>

<style scoped>

</style>
