<template>
    <router-link
        :to="{
            name: `revisions-list-${type}`,
            params: {
                id
            }
        }"
    >
        <div class="revisions">
            <div
                class="revisions-counter"
                :style="{ marginRight: text ? '10px' : '' }"
            >
                {{ revisions }}
                <span
                    v-if="revisions_count_new > 0"
                    class="revisions-new"
                >
                    {{ revisions_count_new }}
                </span>
            </div>
            <span
                v-if="text"
                class="revisions-text"
            >Revisions</span>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'Revisions',
    props: {
        text: Boolean,
        revisions: Number,
        revisions_count_new: Number,
        id: Number,
        type: {
            type: String,
            default: 'landing'
        }
    }
}
</script>

<style lang="scss" scoped>
.revisions {
    display: flex;
    align-items: center;
    &-counter {
        position: relative;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: $orange;
        font-size: 14px;
        font-weight: 500;
        background: $round-count;
    }
    &-new {
        position: absolute;
        top: 0;
        right: -5px;
        width: 20px !important;
        height: 20px !important;
        font-size: 12px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        line-height: 1 !important;
        background-color: rgb(78, 208, 60) !important;
        color: #fff !important;
        border-radius: 50%;
        font-weight: 500;
    }
}
</style>
