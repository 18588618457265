<template>
    <div class="editor-check-box editor-layout__content-form__field">
        <form-checkbox
            v-model="val"
            type="email"
            :field.sync="val"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            :label="name"
            color="$sidebar-orange"
            @input="onChange"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorCheckbox',
    props: {

        value: {},

        errors: {},

        isValid: {},

        disabled: {},
        name: {
            type: String,
            default: 'This link is already placed on the donor'
        }
    },
    data() {
        return {
            val: this.value
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style scoped>

</style>
