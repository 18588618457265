<template>
    <div class="editor-form__category-textarea editor-layout__content-form__field">
        <form-textarea
            v-model="val"
            :field.sync="val"
            :label="label"
            :legend="legend"
            :name="label"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorTextarea',

    props: ['value', 'config', 'errors', 'isValid', 'disabled', 'label', 'legend'],
    data() {
        return {
            val: this.value
        }
    },
    watch: {
        value(val) {
            this.val = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>
