<template>
    <div class="editor-form__category-select editor-layout__content-form__field">
        <!--        <form-select-->
        <!--            v-model="category"-->
        <!--            :field.sync="category"-->
        <!--            :items="categories_list"-->
        <!--            label="Category"-->
        <!--            name="category"-->
        <!--            :errors="errors"-->
        <!--            :is-valid="isValid"-->
        <!--            @input="onChange($event)"-->
        <!--            :disabled="disabled"-->
        <!--        />-->
        <form-select-category
            v-model="category"
            :field.sync="category"
            :items="categories_list"
            label="Category"
            name="category"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorChooseCategory',

    props: ['value', 'config', 'errors', 'isValid', 'disabled', 'categoryName', 'categories'],
    data() {
        return {
            category: this.value
        }
    },
    computed: {
        categories_list() {
            return this.categories
        }
    },
    watch: {
        value(val) {
            this.category = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>
