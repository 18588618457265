<template>
    <div class="editor-form__meta-title editor-layout__content-form__field">
        <form-input
            v-model="password"
            type="password"
            :field.sync="password"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            legend="Password"
            placeholder="Password"
            name="Password"
            @onChange="onChange($event)"
        />
    </div>
</template>

<script>
export default {
    name: 'EditorPassword',

    props: ['value', 'errors', 'isValid', 'config', 'disabled'],
    data() {
        return {
            password: this.value
        }
    },
    watch: {
        value(val) {
            this.password = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('update:field', event)
            this.$emit('input', event)
        }
    }
}
</script>

<style scoped>

</style>
