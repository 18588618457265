<template>
    <div class="editor-form__locale editor-layout__content-form__field">
        <form-select
            v-model="locale"
            :field.sync="locale"
            :items="locales"
            label="Locale"
            name="locale"
            :errors="errors"
            :is-valid="isValid"
            :disabled="disabled"
            @input="onChange($event)"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'EditorChooseLocale',

    props: ['value', 'errors', 'isValid', 'disabled'],
    data() {
        return {
            locale: this.value || 'en'
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        userLocales() {
            return this.user.locale
        },
        locales() {
            return this.userLocales.map((local) => ({
                value: local,
                text: local
            }))
        }
    },
    watch: {
        value(val) {
            this.locale = val
        }
    },
    methods: {
        onChange(event) {
            this.$emit('input', event)
            this.$emit('update:field', event)
        }
    }
}
</script>
