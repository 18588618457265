<template>
    <div
        :class="isShowedSidebar ? 'sidebar-opened' : 'sidebar-closed'"
        class="editor-layout content-layout-wrapper"
    >
        <div class="content">
            <div
                v-if="loading"
                class="line-loader"
            />
            <slot name="header" />
            <div class="editor-layout__content">
                <slot name="content" />
            </div>
        </div>
        <slot name="modals" />
    </div>
</template>

<script>
export default {
    name: 'EditorLayout',
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShowedSidebar: true
        }
    },
    mounted() {
        this.$bus.$on('toggleSidebar', (val) => {
            this.isShowedSidebar = val
        })
    },
    beforeDestroy() {
        this.$bus.$off('toggleSidebar')
    }
}
</script>

<style lang="scss">
    @import "@/assets/styles/dashboard/components/content/content-layout";
    .line-loader {
        height: 4px;
        width: 100%;
        position: absolute;
        overflow: hidden;
        background-color: #ddd;
        top: 0;
        left: 0;
    }
    .line-loader:before{
        display: block;
        position: absolute;
        content: "";
        left: -200px;
        width: 200px;
        height: 4px;
        background-color: #f44336;
        animation: line-loading 1s linear infinite;
    }

    @keyframes line-loading {
        from {left: -200px; width: 30%;}
        50% {width: 30%;}
        70% {width: 70%;}
        80% { left: 50%;}
        95% {left: 120%;}
        to {left: 100%;}
    }
    .editor-layout {
        .content {
            background: #fff;
            position: relative;
            padding: 30px 30px 40px !important;
        }
    }
    .editor-layout__content {
        &-form__field {
            margin-bottom: 28px;
        }
    }
    .editor-form__buttons {
        display: flex;
        button {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .editor-form {
        display: flex;
        flex-wrap: wrap;
        &__row {
            width: 100%;
        }
    }
    .editor-layout {
        input {
            &:disabled {
                border: none !important;
            }
        }
        .form-textarea__input {
            &:disabled {
                border: none !important
            }
        }

    }
    .content-form__description, .editor-form__content {
        &-block {
            position: relative;

        }
        &--legend {
            font-family: 'Mark Pro', sans-serif;
            display: table;
            color: #b3bbc6;
            caret-color: #b3bbc6;
            padding: 2px 5px 2px 5px;
            font-size: 80%;
            position: absolute;
            left: 6px;
            top: -8px;
            border: none;
            z-index: 1;
            background: #fff;
            line-height: 16px;
        }
        &--content {
            min-height: 48px;
            border-radius: 4px ;
            background: rgb(248, 248, 248);
            padding: 10px 10px;
            .article-fl-right {
                padding-left: 200px;
                margin-bottom: 50px;
            }

            .article-fl-left {
                padding-right: 200px;
                margin-bottom: 50px;
            }

            h1 {
                font-size: 32px;
                font-weight: 700;
                padding: 0;
                margin: 50px 0 30px;
            }

            h2 {
                font-weight: bold;
                font-size: 26px;
                color: #1f2939;
                margin: 50px 0 30px 0;
            }

            h3 {
                font-size: 24px;
                font-weight: 700;
                padding: 0;
                margin: 50px 0 30px;
            }

            h4 {
                font-size: 22px;
                font-weight: 700;
                padding: 0;
                margin: 50px 0 30px;
            }

            p {
                font-size: 16px;
                margin: 30px 0 50px 0;

                &.intro-text {
                    padding-right: 200px;
                    margin-bottom: 11px;
                }
            }
            span {
                font-size: inherit ;
                line-height: inherit ;
                font-family: inherit ;
                color: inherit;
            }
            ol {
                counter-reset: items;
                padding-left: 0px;
                margin: 30px 0 50px;
                list-style: none;
                font-size: 16px;
                line-height: 24px;
                li {
                    position: relative;
                    padding-left: 75px;
                    margin-bottom: 30px;
                    counter-increment: items;

                    &::before {
                        content: "0" counter(items);
                        position: absolute;

                        font-size: 30px;
                        font-weight: 500;
                        line-height: 1;

                        color: #dde0e4;
                        left: 0;
                        top: 3px;
                    }

                    &:nth-child(n + 10):before {
                        content: counter(items);
                    }
                }
            }

            .ordered-custom {
                list-style-position: inside;
                li {
                    padding-left: 0px;

                    &:before {
                        display: none;
                    }
                }
            }

            ul {
                margin: 30px 0 50px;
                list-style: none;
                padding: 0;
                font-size: 16px;
                line-height: 24px;
                li {
                    margin-bottom: 15px;
                    padding-left: 20px;
                    position: relative;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:before {
                        content: "";
                        min-width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: #1f2939;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        display: inline-block;
                    }
                }
            }

            table {
                font-size: 16px;
                line-height: 24px;
                ul {
                    margin: 0;
                }
                ol {
                    margin: 0;
                }
            }

            .unordered-custom {
                list-style-type: none ;

                li {
                    color: rgba(30, 40, 56, 0.8);

                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 35px;

                    &:before {
                        font-weight: 400;
                        font-size: 28px;
                        line-height: 1;
                        color: $orange;
                        position: absolute;
                        left: 0;
                        top: 0px;
                    }
                }
            }

            .circle-list {
                li {
                    &:before {
                        content: "\00B0";
                        font-size: 22px;
                        top: 9px;
                    }
                }
            }

            .disc-list {
                li {
                    &:before {
                        content: "\2022";
                        top: -2px;
                        font-size: 28px;
                    }
                }
            }

            .square-list {
                li {
                    &:before {
                        content: "\25A0";
                        top: 9px;
                        font-size: 9px;
                    }
                }
            }

            blockquote {
                position: relative;
                margin: 30px 0 50px;
                padding: 15px 0px 15px 34px ;
                color: #1f2939 ;
                font-size: 18px;
                font-weight: 400;
                font-style: normal;
                line-height: 32px;
                border: none ;
                font-weight: bold;
                p {
                    margin: 0;
                }
                &:before {
                    content: "";
                    width: 4px;
                    height: 100%;
                    background-image: linear-gradient(to top, #ffa23b 0%, #ff5423 100%);
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0px;
                }
            }

            img {
                display: block;
                width: 100%;
                max-width: 500px;
                height: auto;
                margin: 25px auto;
            }

            iframe {
                max-width: 100%;
                margin: 25px auto;
            }

            a {
                color: $orange ;
            }

            strong {
                font-weight: 600;
            }

            em {
                font-style: italic;
            }

            pre {
                font-size: 18px;
                font-weight: 300;
                line-height: 1.56;
                color: #757c89;
                margin-bottom: 20px;
            }

            // New blocks

            .prepend-icon-minus {
                display: inline-block;
                position: relative;
                padding-left: 34px;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    background: url("/images/svg/icon_minus.svg") no-repeat;
                    background-size: cover;
                }
            }
            .prepend-icon-plus {
                display: inline-block;
                position: relative;
                padding-left: 34px;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    background: url("/images/svg/icon_plus.svg") no-repeat;
                    background-size: cover;
                }
            }

            .table--header-border {
                margin: 30px 0 50px 0;
                tbody {
                    tr {
                        border: none;
                        vertical-align: baseline;
                        border-bottom: 1px solid #e7ecf4;
                        &:first-child {
                            td {
                                padding-bottom: 15px;
                            }
                            span {
                                font-weight: bold;
                                font-size: 16px;
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        td {
                            border: none;
                        }
                        &:not(:first-child) {
                            td {
                                padding-top: 15px;
                                padding-right: 55px;
                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            .pluses-minuses {
                margin: 30px 0 50px 0;
                tbody {
                    tr {
                        border-bottom: 1px solid #e7ecf4;
                        &:last-child {
                            border-bottom: none;
                        }
                        td {
                            border: none;
                            width: 345px ;
                            min-width: 345px;
                            max-width: 345px;
                            padding: 20px 40px 20px 20px;
                            &:first-child {
                                width: 170px ;
                                min-width: 170px;
                                max-width: 170px;
                                border-right: 1px solid #e7ecf4;
                                padding-left: 0;
                                .text-bg {
                                    color: #1f2939;
                                    background: #e7ecf4;
                                    border-radius: 4px;
                                    padding: 10px 20px;
                                    font-weight: bold;
                                    font-size: 16px;
                                }
                            }
                            &:nth-child(2) {
                                border-right: 1px solid #e7ecf4;
                            }
                        }
                        &:first-child {
                            td {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .table--no-border {
                margin: 30px 0 50px 0;
                border: none;
                tbody {
                    tr {
                        &:first-child {
                            td {
                                padding-bottom: 20px;
                            }
                        }
                        td {
                            border: none ;
                            border-left: 15px solid transparent ;
                            border-right: 15px solid transparent ;
                            &:first-child {
                                border-left: none ;
                            }
                            &:last-child {
                                border-right: none ;
                            }
                        }
                    }
                }
            }

            .background-good {
                background: rgba(15%, 68%, 38%, 0.05);
                position: relative;
                padding: 30px 40px ;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background: #27ae60;
                }
            }
            .background-bad {
                background: rgba(92%, 34%, 34%, 0.05);
                position: relative;
                padding: 30px 40px ;
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background: rgb(92%, 34%, 34%);
                }
            }

            .prepend-icon-unchecked {
                position: relative;
                display: inline-block ;
                padding-left: 40px ;
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 23px;
                    background: url("/images/svg/list_check.svg") no-repeat;
                    background-size: 100% 100%;
                }
            }
            .prepend-icon-checked {
                position: relative;
                display: inline-block ;
                padding-left: 40px ;
                &:before {
                    content: "";
                    width: 20px;
                    height: 23px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url("/images/svg/list_uncheck.svg") no-repeat;
                    background-size: 100% 100%;
                }
            }
            .prepend-icon-arrowtoleft {
                position: relative;
                display: inline-block ;
                padding-left: 40px ;
                &:before {
                    content: "";
                    width: 18px;
                    height: 23px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url("/images/svg/list_toleft.svg") no-repeat;
                    background-size: 100% 100%;
                }
            }
            .prepend-icon-toright {
                position: relative;
                display: inline-block ;
                padding-left: 40px ;
                &:before {
                    content: "";
                    width: 18px;
                    height: 23px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin-right: 20px;
                    background: url("/images/svg/list_toright.svg") no-repeat;
                    background-size: 100% 100%;
                }
            }

            .fr-file {
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 15px;
                font-weight: bold;
                font-size: 16px;
                line-height: 25px;
                color: $orange ;
                border: 1px solid $orange;
                padding: 14px 30px ;
                padding-left: 60px ;
                border-radius: 4px;
                position: relative;
                transition: 0.3s all;
                &:after {
                    content: "" ;
                    mask-image: url("/images/svg/download_btn.svg");
                    mask-size: cover;
                    background: $orange;
                    width: 20px;
                    height: 25px;
                    position: absolute ;
                    left: 25px;
                    top: 12px;
                    transition: 0.3s all;
                }
                &:hover {
                    background: linear-gradient(128.21deg, #ffa424 0%, $orange 100%);
                    color: #fff ;
                    &:after {
                        background: #fff ;
                    }
                }
            }
            .fr-img-space-wrap {
                margin: 50px 0 30px;
                p {
                    margin: 0;
                    // margin-top: -12px;
                }
            }
            .fr-fil {
                display: block;
                margin: 5px 30px 5px 5px ;
                float: left;
                text-align: left ;
                font-family: $font-family-base ;
                font-size: 14px ;
                line-height: 24px ;
                color: #757c89 ;
                img {
                    max-width: 100% ;
                    margin: 0;
                }
            }
            .fr-fir {
                display: block;
                margin: 5px 5px 5px 30px ;
                float: right;
                text-align: left ;
                font-family: $font-family-base ;
                font-size: 14px ;
                line-height: 24px ;
                color: #757c89 ;
                img {
                    max-width: 100% ;
                    margin: 0;
                }
            }
            *:last-child{
                margin-bottom: 0;
            }
        }
    }
</style>
