import EditorButtonTitle from '@/components/editor/components/EditorButtonTitle';
import EditorCategorySelect from '@/components/editor/components/EditorCategorySelect';
import EditorSelect from '@/components/editor/components/EditorSelect';
import EditorChooseLocale from '@/components/editor/components/EditorChooseLocale';
import EditorContent from '@/components/editor/components/EditorContent';
import EditorDescription from '@/components/editor/components/EditorDescription';
import EditorImg from '@/components/editor/components/EditorImg';
import EditorLink from '@/components/editor/components/EditorLink';
import EditorMainKeywordPlural from '@/components/editor/components/EditorMainKeywordPlural';
import EditorMainKeywordSingular from '@/components/editor/components/EditorMainKeywordSingular';
import EditorMetaDescription from '@/components/editor/components/EditorMetaDescription';
import EditorMetaTitle from '@/components/editor/components/EditorMetaTitle';
import EditorRedirect from '@/components/editor/components/EditorRedirect';
import EditorTitle from '@/components/editor/components/EditorTitle';
import EditorLayout from '@/layouts/EditorLayout'
import EditorHeader from '@/components/editor/EditorHeader';
import EditorTitleSecondary from '@/components/editor/components/EditorTitleSecondary';
import EditorName from '@/components/editor/components/EditorName';
import EditorEmail from '@/components/editor/components/EditorEmail';
import EditorPassword from '@/components/editor/components/EditorPassword';
import EditorPhone from '@/components/editor/components/EditorPhone';
import EditorExcerpt from '@/components/editor/components/EditorExcerpt';

import EditorButtons from '@/components/editor/EditorButtons';
import EditorCheckbox from '@/components/editor/components/EditorCheckbox';
import EditorTextarea from '@/components/editor/components/EditorTextarea';

import ConfirmModal from '@/components/modals/ConfirmModal';

export default {
    components: {
        EditorButtons,
        EditorTitle,
        EditorRedirect,
        EditorMetaTitle,
        EditorMetaDescription,
        EditorMainKeywordSingular,
        EditorMainKeywordPlural,
        EditorLink,
        EditorImg,
        EditorDescription,
        EditorContent,
        EditorChooseLocale,
        EditorCategorySelect,
        EditorSelect,
        EditorButtonTitle,
        EditorLayout,
        EditorHeader,
        EditorTitleSecondary,
        EditorName,
        ConfirmModal,
        EditorEmail,
        EditorPassword,
        EditorPhone,
        EditorExcerpt,
        EditorCheckbox,
        EditorTextarea
    },
    data() {
        return {
            modal: {
                title: '',
                content: '',
                name: ''
            }
        }
    },
    activated() {
        this.setEvents()
    },
    // deactivated() {
    //     this.$bus.$off('editPost')
    //     this.$bus.$off('createPost')
    //     this.$bus.$off('resetPost')
    //     this.$bus.$off('createRevision')
    // },
    methods: {
        setModalConfig(modalTitle, modalContent, modalName) {
            this.modal = {
                title: modalTitle,
                content: modalContent,
                name: modalName
            }
        },
        openResetModal() {
            this.$bus.$emit('openModal', 'reset-modal')
        },
        openSaveModal() {
            this.$bus.$emit('openModal', 'save-modal')
        },
        setEvents() {
            this.$bus.$on('createRevision', () => {
                this.$bus.$emit('openModal', 'revision-modal')
            })
            this.$bus.$on('editPost', () => {
                this.$bus.$emit('openModal', 'edit-modal')
            })
            this.$bus.$on('createPost', () => {
                this.$bus.$emit('openModal', 'save-modal')
            })
            this.$bus.$on('resetPost', () => {
                this.$bus.$emit('openModal', 'reset-modal')
            })
        },
        imageHandler(event) {
            this.thumbnail = event
        }
    }
}
